import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { collapseMoreLikeThisDrawer } from '../../actions/SearchActions';
import {
  selectDrawerContentLoaded,
  selectIsSearchSideMenuOpened,
  selectSearchDrawerNumberItemsVisible,
  selectSearchFeatures,
  selectSearchMenu,
} from '../../selectors/searchSelectors';
import Drawer from '../Drawer';
import HorizontalLayoutHelper from './HorizontalLayoutHelper';
import SimpleSearchResults from './SimpleSearchResults';

// This is in pixels.
const ROW_HEIGHT = 200;

// TODO: Convert to TS when there is more time OMFG-1016 and TOFU-431, but if you see this, feel free to convert it too.
class LayoutBlocksSearchResults extends SimpleSearchResults {
  static propTypes = {
    ...SimpleSearchResults.propTypes,
    currentStockItems: PropTypes.array,
    closeDrawer: PropTypes.func.isRequired,
    drawerContentLoaded: PropTypes.bool,
    drawerIsOpen: PropTypes.bool,
    drawerNumberItemsVisible: PropTypes.number,
    itemsPerRow: PropTypes.number,
  };

  static defaultProps = {
    drawerContentLoaded: false,
    drawerIsOpen: false,
  };

  windowResizeTimer = null;
  layoutEngine = null;
  layoutBlocksPadding = 8; // Padding in pixels for layoutBlocks rendering;

  constructor(props) {
    super(props);
    this.state = {
      needsLayoutShift: true,
      drawerPosition: {},
      layoutBlocksPositions: {},
      layoutBlocksTotalWidth: 1800, // The default value is used for server-side rendering (before a DOM is available)
      layoutBlocksTotalHeight: 400, // Just a sane default value, will be overwritten as the positions are calculated
    };
  }

  componentDidMount() {
    this.initializeLayoutBlocks(this.props);
    // We need a 500ms delay here to give the details page time
    // to get the true width on refresh. It was 84px off
    this.handleResize(500);
    window.addEventListener('resize', () => {
      if (this.props.drawerIsOpen) {
        this.props.closeDrawer();
      }
      this.handleResize();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.initializeLayoutBlocks(nextProps);
  }

  componentDidUpdate(prevProps) {
    //Ensuring we avoid an infinite loop when using update
    if (this.props !== prevProps) {
      // We need to add a 500ms delay, because animations need to
      // finish before we can get the correct container size
      this.handleResize(500);
    }
  }

  handleResize = (resizeDelay) => {
    resizeDelay = resizeDelay || 100;

    // Debounce
    if (this.windowResizeTimer) {
      clearTimeout(this.windowResizeTimer);
    }

    this.windowResizeTimer = setTimeout(this.handleLayoutUpdate, resizeDelay);
  };

  handleLayoutUpdate = () => {
    if (this.searchResultsContainerRef) {
      const containerWidth = this.searchResultsContainerRef.offsetWidth;

      this.setState(
        {
          needsLayoutShift: false,
          layoutBlocksTotalWidth: containerWidth,
        },
        () => this.initializeLayoutBlocks(this.props)
      );
    }
  };

  initializeLayoutBlocks = (props) => {
    const {
      currentStockItems,
      drawerContentLoaded,
      drawerIsOpen,
      drawerSelectedItemId,
      drawerNumberItemsVisible,
      itemsPerRow,
      pagination,
    } = props;
    const layoutBlocksTotalWidth = this.state.layoutBlocksTotalWidth;

    const maxRemainingSpace = 200;
    const hideLastRowIfIncomplete =
      (pagination && pagination.hasMoreResults) || false;
    const minWidthPixels = 120;

    this.layoutEngine = new HorizontalLayoutHelper(
      currentStockItems,
      layoutBlocksTotalWidth,
      ROW_HEIGHT,
      maxRemainingSpace,
      hideLastRowIfIncomplete,
      minWidthPixels,
      (positions, totalHeight, drawerPosition) => {
        this.setState({
          drawerPosition,
          layoutBlocksPositions: positions,
          layoutBlocksTotalHeight: totalHeight,
        });
      }
    );

    if (drawerIsOpen) {
      const selectedItemIndex = currentStockItems.findIndex(
        (wrapper) => wrapper.stockItem.id === drawerSelectedItemId
      );
      if (drawerContentLoaded) {
        this.layoutEngine.setDrawerPopulated(
          selectedItemIndex,
          drawerNumberItemsVisible,
          itemsPerRow
        );
      } else {
        this.layoutEngine.setDrawerLoading(selectedItemIndex);
      }
    } else {
      this.layoutEngine.setDrawerClosed();
    }

    this.layoutEngine.compute();
  };

  getSearchResultsCSSClasses() {
    const cssClasses = ['layout', 'horizontal', 'scale', 'content-info'];

    return super.getSearchResultsCSSClasses().concat(cssClasses);
  }

  getSearchResultsListStyles() {
    const buffer = 10;

    return {
      minHeight: this.state.layoutBlocksTotalHeight + buffer,
    };
  }

  getSearchResultsContainerCSSClasses() {
    const cssClasses = ['layout-container'];

    return super.getSearchResultsCSSClasses().concat(cssClasses);
  }

  getStockItemCardCSSClasses(stockItemDetails) {
    const cssClasses = [];

    const stockItemId = stockItemDetails.stockItem.id;
    const position = this.state.layoutBlocksPositions[stockItemId];

    if (stockItemId in this.state.layoutBlocksPositions) {
      // position is null if card is on last row and hidden
      cssClasses.push(position ? 'layoutBlock' : 'hiddenLayoutBlock');
    }

    return super.getSearchResultsCSSClasses().concat(cssClasses);
  }

  getStockItemCardStyles(stockItemDetails) {
    // default none in case position cannot be calculated for this stock item
    let styles = { display: 'none' };

    const stockItemId = stockItemDetails.stockItem.id;
    const position = this.state.layoutBlocksPositions[stockItemId];

    if (stockItemId in this.state.layoutBlocksPositions && position) {
      // position is null if card is on last row and hidden
      styles = {
        left: position.x + 'px',
        top: position.y + 'px',
        width: position.width + 'px',
        height: position.height + 'px',
        padding: this.layoutBlocksPadding + 'px',
      };
    }

    return _.merge(super.getStockItemCardStyles(), styles);
  }

  getStockItemCardImageCSSClasses(stockItemDetails) {
    const cssClasses = [];

    if (
      'aspectRatio' in stockItemDetails.stockItem &&
      !stockItemDetails.stockItem.aspectRatio
    ) {
      cssClasses.push('noAspectRatio');
    }

    return super.getStockItemCardImageCSSClasses().concat(cssClasses);
  }

  getDrawerStyles() {
    const { width, x, y } = this.state.drawerPosition;
    return {
      left: `${x}px`,
      top: `${y}px`,
      width: `${width}px`,
      'z-index': '100',
    };
  }

  calculateDrawerIndex(selectedItemIndex) {
    return (
      1 + this.layoutEngine.getLastBlockIndexInContainingRow(selectedItemIndex)
    );
  }

  calculateIndicatorOffset(selectedItemId) {
    return this.layoutEngine.getXMidpointForBlockById(selectedItemId);
  }

  buildDrawer(drawerSelectedItemId) {
    const selectedItemIndicatorOffset =
      this.calculateIndicatorOffset(drawerSelectedItemId);
    return (
      <Drawer
        indicatorClasses="pointer"
        key={`drawer-for-${drawerSelectedItemId}`}
        selectedItemId={drawerSelectedItemId}
        selectedItemIndicatorOffset={selectedItemIndicatorOffset}
        customStyles={this.getDrawerStyles()}
      />
    );
  }
}

function mapStateToProps(state) {
  const menu = selectSearchMenu(state);

  return {
    drawerContentLoaded: selectDrawerContentLoaded(state),
    features: selectSearchFeatures(state),
    isSideMenuOpened: selectIsSearchSideMenuOpened(state),
    drawerNumberItemsVisible: selectSearchDrawerNumberItemsVisible(state),
    isNewSideMenuOpened: menu.isOpen,
    isNewSideMenuSubMenuOpened: menu.subMenu.isOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeDrawer: () => dispatch(collapseMoreLikeThisDrawer()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutBlocksSearchResults);
